import $ from 'jquery';
var popupS = require('popups');
var Datepicker = require('@fengyuanchen/datepicker/dist/datepicker.esm');
import jqueryValidation from 'jquery-validation';
import Namen from './components/Namen';
import SelectBoxes from './components/SelectBoxes';

$.fn.datepicker.languages['en-GB'] = {
    format: 'dd/mm/yyyy'
};
$.validator.messages.required = '';
$.validator.messages.email = '';
$.fn.datepicker.languages['fr-FR'] = {
    format: 'dd/mm/yyyy',
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
    weekStart: 1,
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jui', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec']
};

$.fn.datepicker.languages['nl-NL'] = {
    format: 'dd-mm-yyyy',
    days: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],
    daysShort: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
    daysMin: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
    weekStart: 1,
    months: ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'],
    monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']
};

(function ($) {

    var methods = {
        init: function () {
            var platform = /(iphone|ipod|ipad)/.test(navigator.userAgent.toLowerCase()) ? ' ios' : '';
            return this.each(function (i, el) {
                $(el).wrap('<span class="fancyselect' + platform + '" />')
                    .before('<span class="arrows" />')
                    .before('<span class="selecttext" />')
                    .fadeTo(0, 0)
                    .focus(function () { $(el).parent('span').addClass('focus'); })
                    .blur(function () { $(el).parent('span').removeClass('focus'); })
                    .on("change keydown keyup", function () { $(el).fancyselect('update'); })
                    .fancyselect('update');
                if ($(el).attr("disabled")) $(el).fancyselect('disable');
            });
        },
        destroy: function () {
            return this.each(function (i, el) {
                $(el).siblings('span').remove();
                $(el).unwrap().fadeTo(0, 1).unbind('focus blur change keydown keyup');
            });
        },
        enable: function () {
            return this.each(function (i, el) {
                $(el).removeAttr('disabled').parent().removeClass('disabled');
            });
        },
        disable: function () {
            return this.each(function (i, el) {
                $(el).attr("disabled", "disabled").parent().addClass('disabled');
            });
        },
        update: function () {
            return this.each(function (i, el) {
                $(el).prev(".selecttext").text($(el).children('option:selected').text());
            });
        }
    };

    $.fn.fancyselect = function (method) {
        if (methods[method]) {
            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
        }
        else if (typeof method === 'object' || !method) {
            return methods.init.apply(this, arguments);
        }
        else {
            $.error('Method ' + method + ' does not exist for .fancyselect()');
        }
    };

})($);



$(document).ready(function () {
    $('#reset').on('click', function (e) {
        e.preventDefault();
        $('input:radio[name="vm"]').each(function () { $(this).prop('checked', false); });
        $('input:radio[name="nm"]').each(function () { $(this).prop('checked', false); });
    });
    $('.acc-content').hide();
    $('.acc-header a').on('click', function (e) {
        e.preventDefault();
        if ($(this).parent().parent().hasClass('open')) {
            $(this).parent().next().slideUp("slow");
            $(this).parent().parent().removeClass('open');
        } else {
            $('.open .acc-content').slideUp("slow").parent().removeClass('open');
            $(this).parent().next().stop().slideDown("slow").parent().addClass("open");
        }
    })
    $('.check').on("click", function (e) {
        e.preventDefault();
        const $url = $(this).attr("href");
        $.get("check.php?code=" + $('#code').val(), function (data) {
            if (data == "1") {
                document.location.href = $url;
            } else {
                popupS.window({
                    mode: 'alert',
                    className: 'popup',
                    closeBtn: '',
                    content: 'Ongeldige Code | Code invalide | Invalid code'
                });
            }
        })
    })
    if (window.taal) {
        $('#subscribe').click(function () {
            popupS.window({
                mode: 'modal',
                className: 'popup',
                closeBtn: '',
                content: '<iframe class="iframe" src="subscribe_' + window.taal + '.php?dt=1"></iframe>',
                onClose: function () {
                    $.get("checkSession.php", function (data) {
                        if (data == "0") {
                            window.location.href = "https://ourjourney.vistalink.eu/index.php";
                            //console.log("terug naar start");
                        } else {
                            //console.log('nog niet volledig geregistreerd');
                        }
                    })
                }
            });
        })
    }

    $('select').fancyselect();

    $('#hoeveel').on('change', function (e) {
        if ($(this).val() == "") {
            document.getElementById('holder').innerHTML == "";
            $('#holder').hide();
        } else {
            $('#holder').hide();
            new Namen($(this).val(), document.getElementById('holder'))
            $('#holder').slideDown();
        }
    })
    $('#hotel-ja').click(function () {
        $('#selectboxesHolder').hide();
        new SelectBoxes(document.getElementById('selectboxesHolder'));
        $('#selectboxesHolder select').fancyselect();
        $('[data-toggle="datepicker"]').datepicker({
            language: getLang(),
            format: 'dd-mm-yyyy',
            startDate: '01-05-2018',
            endDate: '31-05-2018',
            autoHide: true
        });
        $('#selectboxesHolder').slideDown();
    })
    function getLang() {
        if (window.taal == "nl") {
            return "nl-NL";
        }
        if (window.taal == "fr") {
            return "fr-FR";
        }
        if (window.taal == "en") {
            return "en-GB";
        }
    }
    $('#hotel-nee').click(function () {
        $('#selectboxesHolder').empty();
        $('#selectboxesHolder').hide();
    })
    $("#subscriptionForm").validate({

        errorPlacement: function (error, element) {
            if (element.attr("name") == "aantal-personen") {
                element.parent().addClass("error");
            } else {
                if (element.attr("name") == "aantal-kamers") {
                    element.parent().addClass("error");
                } else {
                    if (element.attr("name") == "vm" || element.attr("name") == "nm") {
                        error.insertAfter("#programma_place");
                    } else {
                        error.insertAfter(element);
                    }
                }
            }
        },

        rules: {
            vm: {
                required: function (element) {
                    if ($("input:radio[name='nm']:checked").val()) {
                        return false;
                    } else {
                        return true;
                    }
                }
            },
            nm: {
                required: function (element) {
                    if ($("input:radio[name='vm']:checked").val()) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
            }
        }
    });

    $("form").on('change', '#Check-in', function () {
        $('#Check-in').valid();
    });
    $("form").on('change', '#Check-out', function () {
        $('#Check-out').valid();
    });

    $("input:radio[name='vm'], input:radio[name='nm']").click(function () {
        $("input:radio[name='vm']").valid();
        $("input:radio[name='nm']").valid();
    });
    $("a.submit").click(function (e) {
        e.preventDefault();
        $('.fancyselect').removeClass('error');
        $("#subscriptionForm").submit();
    })
})