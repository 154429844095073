export default class Namen {
    constructor(aantal, holder) {
        this.aantal = aantal;
        this.holder = holder;
        this.holder.innerHTML = "";
        this.generateHTML();
    }
    generateHTML() {
        let html = "";
        for (let i = 1; i <= this.aantal; i++) {
            if (window.taal == "nl") {
                html += `
                <div class="row row-4">
                    <label for="voornaam-${i}">Voornaam-${i}*</label>
                    <input type="text" name="voornaam-${i}" id="voornaam-${i}" class="required">
                    <label for="naam-${i}">Naam-${i}*</label>
                    <input type="text" name="naam-${i}" id="naam-${i}" class="required">
                </div>            
            `;
            }
            if (window.taal == "fr") {
                html += `
                    <div class="row row-4">
                        <label for="voornaam-${i}">Prénom-${i}*</label>
                        <input type="text" name="voornaam-${i}" id="voornaam-${i}" class="required">
                        <label for="naam-${i}">Nom-${i}*</label>
                        <input type="text" name="naam-${i}" id="naam-${i}" class="required">
                    </div>            
                `;
            }
            if (window.taal == "en") {
                html += `
                        <div class="row row-4">
                            <label for="voornaam-${i}">Name-${i}*</label>
                            <input type="text" name="voornaam-${i}" id="voornaam-${i}" class="required">
                            <label for="naam-${i}">Surname-${i}*</label>
                            <input type="text" name="naam-${i}" id="naam-${i}" class="required">
                        </div>            
                    `;
            }
        }
        this.holder.insertAdjacentHTML('afterbegin', html);
    }
}