export default class SelectBoxes {
    constructor(holder) {
        this.holder = holder;
        this.holder.innerHTML = "";
        this.generateHTML();
    }
    generateHTML() {
        let html = "";
        if (window.taal == "nl") {
            html = `<div class="selectboxes">


        
        <div id="block-1">
            <input type="text" data-toggle="datepicker" class="required" id="Check-in" name="Check-in" placeholder="Check-in">
        </div>
        
        
        <div id="block-2">
            <input type="text" class="required" data-toggle="datepicker" id="Check-out" name="Check-out" placeholder="Check-out">
        </div>
        
        <select name="aantal-personen" class="required" id="aantal-personen">
        <option value="">Aantal personen</option>
        <option value="1">1 persoon</option>
        <option value="2">2 personen</option>
        <option value="3">3 personen</option>
        <option value="4">4 personen</option>
        <option value="5">5 personen</option>
        </select>
        <select name="aantal-kamers" class="required" id="aantal-kamers">
        <option value="">Aantal kamers</option>
        <option value="1">1 kamer</option>
        <option value="2">2 kamers</option>
        <option value="3">3 kamers</option>
        <option value="4">4 kamers</option>
        <option value="5">5 kamers</option>
        </select></div>
            `;
        }
        if (window.taal == "en") {
            html = `<div class="selectboxes">


        
        <div id="block-1">
            <input type="text" data-toggle="datepicker" class="required" id="Check-in" name="Check-in" placeholder="Check-in">
        </div>
        
        
        <div id="block-2">
            <input type="text" class="required" data-toggle="datepicker" id="Check-out" name="Check-out" placeholder="Check-out">
        </div>
        
        <select name="aantal-personen" class="required" id="aantal-personen">
        <option value="">Number of persons</option>
        <option value="1">1 person</option>
        <option value="2">2 persons</option>
        <option value="3">3 persons</option>
        <option value="4">4 persons</option>
        <option value="5">5 persons</option>
        </select>
        <select name="aantal-kamers" class="required" id="aantal-kamers">
        <option value="">Number of rooms</option>
        <option value="1">1 room</option>
        <option value="2">2 rooms</option>
        <option value="3">3 rooms</option>
        <option value="4">4 rooms</option>
        <option value="5">5 rooms</option>
        </select></div>
            `;
        }
        if (window.taal == "fr") {
            html = `<div class="selectboxes">


        
        <div id="block-1">
            <input type="text" data-toggle="datepicker" class="required" id="Check-in" name="Check-in" placeholder="Check-in">
        </div>
        
        
        <div id="block-2">
            <input type="text" class="required" data-toggle="datepicker" id="Check-out" name="Check-out" placeholder="Check-out">
        </div>
        
        <select name="aantal-personen" class="required" id="aantal-personen">
        <option value="">Nombre de personnes</option>
        <option value="1">1 persone</option>
        <option value="2">2 personnes</option>
        <option value="3">3 personnes</option>
        <option value="4">4 personnes</option>
        <option value="5">5 personnes</option>
        </select>
        <select name="aantal-kamers" class="required" id="aantal-kamers">
        <option value="">Nombre de chambres</option>
        <option value="1">1 chambre</option>
        <option value="2">2 chambres</option>
        <option value="3">3 chambres</option>
        <option value="4">4 chambres</option>
        <option value="5">5 chambres</option>
        </select></div>
            `;
        }
        //  console.log(html);
        this.holder.insertAdjacentHTML('afterbegin', html);
    }
}